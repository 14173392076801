import React from 'react';
import type { Reservation } from '../models';
import { LocationObject } from '../models/reservation';


export const padString = (value: any, padChar = '0', maxLength = 2) => {
    let output = `${value}`;
    let len = output.length;
    while (len < maxLength) {
        output = `${padChar}${output}`;
        len = output.length;
    }
    return output;
};

// get nested value from object or `null`
export const getOrNull = (path: string | Function, obj: Object = {}) => {
    if (typeof path === 'function') {
        return path(obj);
    }
    return path.split('.').reduce((acc: any, curr: string) => {
        if (acc) {
            if (typeof acc[curr] === 'undefined') {
                return null;
            }
            if (acc[curr] === 0) {
                return 0;
            }
            if (acc[curr]) {
                return acc[curr];
            }
        }
        return null;
    }, obj);
};

export const isHexColor = (color: string) => {
    return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(color);
};

//check if hex color is white(ish)
export const isWhite = (hex: string) => {
    try {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result && parseInt(result[1], 16) > 200 && parseInt(result[2], 16) > 200 && parseInt(result[3], 16) > 200
            ? true
            : false;
    } catch (e) {
        return undefined;
    }
};

export const capitalize = (value = '') => `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;

export const exec = (promise: Promise<any>, log = false) => {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((error) => {
            if (log) {
                console.error(error);
            }
            return [error];
        });
};

export const generateRandomString = (length = 5) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

export const isMobileTablet = () => {
    let check = false;
    if (typeof navigator === 'undefined' || typeof window === 'undefined') {
        return check;
    }
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4),
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

// function to replace new line characters with <br> tags using tsx
export const newLineToBr = (text: string): JSX.Element[] => {
    return text.split('\n').map((item, key) => {
        return React.createElement('span', { key: key }, item, React.createElement('br'));
    });
};

export const isAirbnbReservation = (reservation: Reservation) => {
    const isAirbnbSource = /airbnb/i.test(reservation.source || '');
    const isAirbnbEmail = /airbnb\.com$/i.test(reservation.guest_email || '');
    return isAirbnbSource || isAirbnbEmail;
};

export const isProduction = (process.env.STAGE_NAME || process.env.STAGE || '').includes('prod');

export function camelCaseKeysToUnderscore(obj: {
    [key: string]:
    | string
    | {
        [key: string]: string;
    };
}) {
    if (typeof obj != 'object') return obj;

    for (var oldName in obj) {
        // Camel to underscore
        let newName = oldName.replace(/([A-Z])/g, function ($1) {
            return '_' + $1.toLowerCase();
        });

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }

        // Recursion
        if (typeof obj[newName] !== 'string') {
            //@ts-ignore
            obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
        }
    }
    return obj;
}


export function reduceImageSize(base64Image: string, percentage: number) {
    // Create a new virtual image element
    var img = document.createElement('img');

    // Set the base64 image string as the source of the virtual image element
    img.src = base64Image;

    // Create a canvas element
    var canvas = document.createElement('canvas');

    // Calculate the new width and height based on the given percentage
    var newWidth = img.width * (percentage / 100);
    var newHeight = img.height * (percentage / 100);

    // Set the canvas width and height to the new dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image onto the canvas with the new dimensions
    var ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Get the base64 representation of the reduced size image from the canvas
        var reducedSizeBase64 = canvas.toDataURL();

        // Return the reduced size base64 image string
        return reducedSizeBase64;

    }
    // something went wrong getting context, return the original image
    // but this will probably never happen, mostly here to satisfy
    // the type checker
    return base64Image
}

export function formatAddressForAutofill(location: LocationObject) {
    const { street, city, state, zipcode, country } = location;
    return `${street}, ${city}, ${state} ${zipcode}, ${country}`;
}


export function deepClone(source: any) {
    return JSON.parse(JSON.stringify(source))
}

/**
 * Removes the trailing slash from a given URL if it exists.
 *
 * @param url - The URL to remove the trailing slash from.
 * @returns The URL without the trailing slash.
 */
export function removeTrailingSlashFromUrl(url: string) {
    if (url.charAt(url.length - 1) === '/') {
        return url.slice(0, -1)
    } return url
}